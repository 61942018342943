<template>
    <div>
        <el-button :disabled="loading" type="primary" @click="openDialog()">新建</el-button>
    </div>
    <el-table v-loading="loading" :data="data" style="margin-top: 10px">
        <el-table-column label="封面图" width="300" prop="cover">
            <template #default="scope">
                <el-image lazy :src="`https://color-cdn.rabigame.com/${scope.row.cover}`"
                          style="width: 280px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="活跃" width="100">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="120"></el-table-column>
        <el-table-column label="标题" prop="i18n_name.EN" width="200"></el-table-column>
        <el-table-column label="描述" prop="i18n_desc.EN" width="200"></el-table-column>
        <el-table-column label="操作" width="250">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="120px">
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item :label="`${k}描述`" v-for="(_, k) in form.i18n_desc" :prop="`i18n_desc.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}描述`}]:null">
                <el-input v-model="form.i18n_desc[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="跳转链接" prop="url" :rules="[{required: true, message: '请输入跳转链接'}]">
                <el-input v-model="form.url" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="封面" prop="cover" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'event'}" :onProgress="handleProgress">
                    <img v-if="form.cover" :src="`https://color-cdn.rabigame.com/${form.cover}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {ElMessageBox} from "element-plus";

export default {
    name: "View",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false,
            form: {
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                i18n_desc: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                cover: '',
                url: '',
                active: false,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/event`).then(res => {
                this.data = res.data.data.eventList;
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.editing = null;
            this.loading = this.dialog_opened = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/event/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/event`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/event`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/event/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            });
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.cover = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/event/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>