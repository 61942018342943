<template>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <template v-if="row.style === 1">
            <vue-draggable-next v-model="data" :animation="300" handle=".wrapper" class="collection" @change="sort">
                <div class="wrapper" v-for="d in data" v-loading>
                    <template v-for="collection in collection_list">
                        <template v-if="collection.id === d.collection_id">
                            <el-image :src="`https://color-cdn.rabigame.com/${collection.cover}`" fit="cover"
                                      style="height: 160px"></el-image>
                            <div class="cover"
                                 :style="{backgroundImage: `linear-gradient(to top, ${collection.color}, ${collection.color} 20%, transparent 50%)`}"></div>
                            <el-tag class="collection-tag" round effect="plain" v-if="collection.price" size="small">
                                <el-icon>
                                    <Coin/>
                                </el-icon>
                                <span>{{ collection.discounted || collection.price }}&nbsp;</span>
                                <span v-if="collection.discounted" style="text-decoration: line-through;color: #A8ABB2">
                                    {{ collection.price }}</span>
                            </el-tag>
                            <el-icon @click="removeCollection(d)">
                                <CircleClose/>
                            </el-icon>
                        </template>
                    </template>
                </div>
            </vue-draggable-next>
            <el-table v-loading="loading" :data="collectionFilter" style="margin-top: 10px">
                <el-table-column label="封面" width="150" prop="cover">
                    <template #default="scope">
                        <el-image style="width: 140px" lazy
                                  :src="`https://color-cdn.rabigame.com/${scope.row.cover}`"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="主题色" width="100" prop="color"></el-table-column>
                <el-table-column label="名称" width="150" prop="i18n_name">
                    <template #default="scope">
                        {{ scope.row.i18n_name.EN }}
                    </template>
                </el-table-column>
                <el-table-column label="描述" width="300" prop="i18n_name">
                    <template #default="scope">
                        {{ scope.row.i18n_desc.EN }}
                    </template>
                </el-table-column>
                <el-table-column label="价格" width="100" prop="price"></el-table-column>
                <el-table-column label="折后价" width="100" prop="discounted"></el-table-column>
                <el-table-column label="操作" width="200">
                    <template #default="scope">
                        <el-button type="success" :disabled="loading" @click="addCollection(scope.row)">添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else-if="row.style === 2">
            <el-table v-loading="loading" :data="gallery_list" style="margin-top: 20px">
                <el-table-column label="封面" width="150" prop="cover">
                    <template #default="scope">
                        <el-image style="width: 140px" lazy
                                  :src="`https://color-cdn.rabigame.com/${scope.row.cover}`"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="主题色" width="100" prop="color"></el-table-column>
                <el-table-column label="名称" width="150" prop="i18n_name">
                    <template #default="scope">
                        {{ scope.row.i18n_name.EN }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template #default="scope">
                        <el-button size="small" :disabled="gallery && scope.row.id === gallery.gallery_id"
                                   type="primary"
                                   @click="changeGallery(scope.row)">
                            {{ gallery && scope.row.id === gallery.gallery_id ? '已' : '' }}选中
                        </el-button>
                        <el-button size="small" type="success" :disabled="loading"
                                   @click="$refs.image.openDialog(scope.row)">
                            内容
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else-if="row.style === 4">
            <vue-draggable-next v-model="data" :animation="300" handle=".wrapper" class="jigsaw" @change="sort">
                <div class="wrapper" v-for="d in data" v-loading>
                    <template v-for="jigsaw in jigsaw_list">
                        <template v-if="jigsaw.id === d.jigsaw_id">
                            <el-image :src="`https://color-cdn.rabigame.com/${jigsaw.cover}`" fit="cover"
                                      style="height: 160px"></el-image>
                            <div class="cover"
                                 :style="{backgroundImage: `linear-gradient(to top, ${jigsaw.color}, ${jigsaw.color} 20%, transparent 50%)`}"></div>
                            <el-icon @click="removeJigsaw(d)">
                                <CircleClose/>
                            </el-icon>
                        </template>
                    </template>
                </div>
            </vue-draggable-next>
            <el-table v-loading="loading" :data="jigsawFilter" style="margin-top: 10px">
                <el-table-column label="封面" width="150" prop="cover">
                    <template #default="scope">
                        <el-image style="width: 140px" lazy
                                  :src="`https://color-cdn.rabigame.com/${scope.row.cover}`"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="主题色" width="100" prop="color"></el-table-column>
                <el-table-column label="名称" width="150" prop="i18n_name">
                    <template #default="scope">
                        {{ scope.row.i18n_name.EN }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template #default="scope">
                        <el-button type="success" :disabled="loading" @click="addJigsaw(scope.row)">添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else-if="row.style === 5">
            <vue-draggable-next v-model="data" :animation="300" handle=".wrapper" class="level" @change="sort">
                <div class="wrapper" v-for="d in data" v-loading>
                    <template v-for="level in level_list">
                        <template v-if="level.id === d.level_id">
                            <el-image :src="`https://color-cdn.rabigame.com/${level.cover}`" fit="cover"
                                      style="height: 160px"></el-image>
                            <div class="cover"
                                 :style="{backgroundImage: `linear-gradient(to top, ${level.color}, ${level.color} 20%, transparent 50%)`}"></div>
                            <el-icon @click="removeLevel(d)">
                                <CircleClose/>
                            </el-icon>
                        </template>
                    </template>
                </div>
            </vue-draggable-next>
            <el-table v-loading="loading" :data="levelFilter" style="margin-top: 10px">
                <el-table-column label="封面" width="150" prop="cover">
                    <template #default="scope">
                        <el-image style="width: 140px" lazy
                                  :src="`https://color-cdn.rabigame.com/${scope.row.cover}`"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="主题色" width="100" prop="color"></el-table-column>
                <el-table-column label="名称" width="150" prop="i18n_name">
                    <template #default="scope">
                        {{ scope.row.i18n_name.EN }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template #default="scope">
                        <el-button type="success" :disabled="loading" @click="addLevel(scope.row)">添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else-if="row.style === 6">
            <vue-draggable-next v-model="data" :animation="300" handle=".wrapper" class="event" @change="sort">
                <div class="wrapper" v-for="d in data" v-loading>
                    <template v-for="event in event_list">
                        <template v-if="event.id === d.event_id">
                            <el-image :src="`https://color-cdn.rabigame.com/${event.cover}`" fit="cover"
                                      style="height: 160px"></el-image>
                            <div class="cover"
                                 :style="{backgroundImage: `linear-gradient(to top, ${event.color}, ${event.color} 20%, transparent 50%)`}"></div>
                            <el-icon @click="removeCollectionEvent(d)">
                                <CircleClose/>
                            </el-icon>
                        </template>
                    </template>
                </div>
            </vue-draggable-next>
            <el-table v-loading="loading" :data="eventFilter" style="margin-top: 10px">
                <el-table-column label="封面" width="150" prop="cover">
                    <template #default="scope">
                        <el-image style="width: 140px" lazy
                                  :src="`https://color-cdn.rabigame.com/${scope.row.cover}`"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="主题色" width="100" prop="color"></el-table-column>
                <el-table-column label="名称" width="150" prop="i18n_name">
                    <template #default="scope">
                        {{ scope.row.i18n_name.EN }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template #default="scope">
                        <el-button type="success" :disabled="loading" @click="addCollectionEvent(scope.row)">添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template #footer>
            <el-button type="primary" @click="reset" :loading="loading">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "Content",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], row: null, dialog_opened: false, collection_list: [], gallery_list: [],
            story_list: [], gallery: null, jigsaw_list: [], level_list: [], event_list: [],
        }
    },
    methods: {
        openDialog(row) {
            this.dialog_opened = this.loading = true;
            this.gallery = null;
            this.row = row;
            if (row.style === 1) {
                axios.all([
                    axios.get(`/cms/v1/discovery/collection`, {params: {row_id: row.id}}).then(res => {
                        this.data = res.data.data.collectionList;
                    }),
                    axios.get(`/cms/v1/collection`).then(res => {
                        this.collection_list = res.data.data.collectionList;
                    })
                ]).then(_ => {
                    this.loading = false;
                })
            } else if (row.style === 2) {
                axios.all([
                    axios.get(`/cms/v1/discovery/gallery`, {params: {row_id: row.id}}).then(res => {
                        if (res.data.data) {
                            this.gallery = res.data.data;
                        }
                    }),
                    axios.get(`/cms/v1/gallery`).then(res => {
                        this.gallery_list = res.data.data.galleryList;
                    })
                ]).then(_ => {
                    this.loading = false;
                })
            } else if (row.style === 3) {
                axios.all([
                    axios.get(`/cms/v1/discovery/story`, {params: {row_id: row.id}}).then(res => {
                    }),
                    axios.get(`/cms/v1/story`).then(res => {
                        this.story_list = res.data.data.storyList;
                    })
                ])
            } else if (row.style === 4) {
                axios.all([
                    axios.get(`/cms/v1/discovery/jigsaw`, {params: {row_id: row.id}}).then(res => {
                        this.data = res.data.data.jigsawList;
                    }),
                    axios.get(`/cms/v1/jigsaw`).then(res => {
                        this.jigsaw_list = res.data.data.jigsawList;
                    })
                ]).then(_ => {
                    this.loading = false;
                })
            } else if (row.style === 5) {
                axios.all([
                    axios.get(`/cms/v1/discovery/level`, {params: {row_id: row.id}}).then(res => {
                        this.data = res.data.data.levelList;
                    }),
                    axios.get(`/cms/v1/level`).then(res => {
                        this.level_list = res.data.data.levelList;
                    })
                ]).then(_ => {
                    this.loading = false;
                })
            } else if (row.style === 6) {
                axios.all([
                    axios.get(`/cms/v1/discovery/collection-event`, {params: {row_id: row.id}}).then(res => {
                        this.data = res.data.data.eventList;
                    }),
                    axios.get(`/cms/v1/collection-event`).then(res => {
                        this.event_list = res.data.data.eventList;
                    })
                ]).then(_ => {
                    this.loading = false;
                })
            }
        },
        reset() {
            this.dialog_opened = this.loading = false;
            this.$emit('close')
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            });
            if (this.row.style === 1) {
                axios.post(`/cms/v1/sort/collection`, {sort}).then(_ => {
                })
            } else if (this.row.style === 3) {
                axios.post(`/cms/v1/sort/story`, {sort}).then(_ => {
                })
            } else if (this.row.style === 4) {
                axios.post(`/cms/v1/sort/jigsaw`, {sort}).then(_ => {
                })
            } else if (this.row.style === 5) {
                axios.post(`/cms/v1/sort/level`, {sort}).then(_ => {
                })
            } else if (this.row.style === 6) {
                axios.post(`/cms/v1/sort/collection-event`, {sort}).then(_ => {
                })
            }
        },
        changeGallery(gallery) {
            if (this.gallery) {
                this.loading = true;
                axios.delete(`/cms/v1/discovery/gallery/${this.gallery.id}`).then(res => {
                    axios.post(`/cms/v1/discovery/gallery`, {gallery_id: gallery.id, row_id: this.row.id}).then(res => {
                        this.gallery = res.data.data;
                        this.loading = false;
                    })
                });
            } else {
                axios.post(`/cms/v1/discovery/gallery`, {gallery_id: gallery.id, row_id: this.row.id}).then(res => {
                    this.gallery = res.data.data;
                    this.loading = false;
                })
            }
        },
        addCollection(collection) {
            this.loading = true;
            axios.post(`/cms/v1/discovery/collection`, {
                row_id: this.row.id,
                collection_id: collection.id
            }).then(res => {
                this.data.unshift(res.data.data);
                this.sort();
                this.loading = false;
            })
        },
        addJigsaw(jigsaw) {
            this.loading = true;
            axios.post(`/cms/v1/discovery/jigsaw`, {
                row_id: this.row.id,
                jigsaw_id: jigsaw.id
            }).then(res => {
                this.data.unshift(res.data.data);
                this.sort();
                this.loading = false;
            })
        },
        addLevel(level) {
            this.loading = true;
            axios.post(`/cms/v1/discovery/level`, {
                row_id: this.row.id,
                level_id: level.id
            }).then(res => {
                this.data.unshift(res.data.data);
                this.sort();
                this.loading = false;
            })
        },
        addCollectionEvent(event) {
            this.loading = true;
            axios.post(`/cms/v1/discovery/collection-event`, {
                row_id: this.row.id,
                event_id: event.id
            }).then(res => {
                this.data.unshift(res.data.data);
                this.sort();
                this.loading = false;
            })
        },
        removeCollection(r) {
            this.loading = true;
            axios.delete(`/cms/v1/discovery/collection/${r.id}`).then(res => {
                this.data.splice(this.data.indexOf(r), 1);
                this.loading = false;
            })
        },
        removeJigsaw(r) {
            this.loading = true;
            axios.delete(`/cms/v1/discovery/jigsaw/${r.id}`).then(res => {
                this.data.splice(this.data.indexOf(r), 1);
                this.loading = false;
            })
        },
        removeLevel(r) {
            this.loading = true;
            axios.delete(`/cms/v1/discovery/level/${r.id}`).then(res => {
                this.data.splice(this.data.indexOf(r), 1);
                this.loading = false;
            })
        },
        removeCollectionEvent(r) {
            this.loading = true;
            axios.delete(`/cms/v1/discovery/collection-event/${r.id}`).then(res => {
                this.data.splice(this.data.indexOf(r), 1);
                this.loading = false;
            })
        },
    },
    computed: {
        collectionFilter() {
            return this.collection_list.filter(c => {
                let valid = true;
                if (valid) {
                    this.data.forEach(d => {
                        if (d.collection_id === c.id) {
                            valid = false;
                        }
                    })
                }
                return valid
            })
        },
        jigsawFilter() {
            return this.jigsaw_list.filter(c => {
                let valid = true;
                if (valid) {
                    this.data.forEach(d => {
                        if (d.jigsaw_id === c.id) {
                            valid = false;
                        }
                    })
                }
                return valid
            })
        },
        levelFilter() {
            return this.level_list.filter(c => {
                let valid = true;
                if (valid) {
                    this.data.forEach(d => {
                        if (d.level_id === c.id) {
                            valid = false;
                        }
                    })
                }
                return valid
            })
        },
        eventFilter() {
            return this.event_list.filter(c => {
                let valid = true;
                if (valid) {
                    this.data.forEach(d => {
                        if (d.event_id === c.id) {
                            valid = false;
                        }
                    })
                }
                return valid
            })
        },
    }
}
</script>

<style scoped>
.collection, .gallery, .jigsaw, .level, .event, .collection-event {
    display: flex;
    overflow-x: auto;
}

.collection .wrapper, .jigsaw .wrapper, .event .wrapper, .collection-event .wrapper {
    height: 200px;
    width: 160px;
    border-radius: 10px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    margin: 0 5px;
}

.level .wrapper {
    height: 160px;
    border-radius: 10px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    margin: 0 5px;
}

.wrapper > .el-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.collection .cover, .jigsaw .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.collection-tag.el-tag--plain {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #FFF;
    border-color: #FFF;
    color: #303133;
    font-size: 12px;
}

.collection-tag.el-tag--dark {
    position: absolute;
    left: 5px;
    bottom: 5px;
    background-color: #303133;
    border-color: #303133;
    color: #F5F7Fa
}
</style>