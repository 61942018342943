<template>
    <downloadable-table :data="[{a: 1, b: 2}]">
        <el-table-column prop="a"></el-table-column>
        <el-table-column prop="b"></el-table-column>
    </downloadable-table>
</template>

<script>
import DownloadableTable from "../base/DownloadableTable";
export default {
    name: "Debug",
    components: {DownloadableTable}
}
</script>

<style scoped>

</style>