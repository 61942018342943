<template>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-table style="margin-top: 10px" v-loading="loading" :data="data">
            <el-table-column label="预览图" prop="prefix" width="230">
                <template #default="scope">
                    <template v-for="image in $root.image_list">
                        <template v-if="image._id === scope.row.image_id">
                            <el-image lazy :src="`https://color-cdn.rabigame.com/${image.thumbnail}`"
                                      style="width: 100px"></el-image>
                            <el-image lazy :src="`https://color-cdn.rabigame.com/material/${image.key}/finished.webp`"
                                      style="width: 100px"></el-image>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="id" prop="image_id" width="100"></el-table-column>
            <el-table-column label="名称" prop="i18n_name.EN" width="150"></el-table-column>
            <el-table-column label="描述" prop="i18n_desc.EN" width="250"></el-table-column>
            <el-table-column label="上线时间" prop="online_date" width="160">
                <template #default="scope">
                    <template v-for="image in $root.image_list">
                        <template v-if="image._id === scope.row.image_id">
                            {{ image.online_date }}
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button type="success" circle icon="Top" :loading="loading" size="small"
                               :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                    <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                               :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                    <el-button size="small" :disabled="loading" @click="openDialog1(scope.row)" circle
                               icon="Edit"></el-button>
                    <el-button size="small" type="danger" :disabled="loading" @click="removeImage(scope.row)" circle
                               icon="Delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-button type="primary" @click="openDialog1()" :disabled="loading">添加图片</el-button>
        <template #footer>
            <el-button type="primary" :disabled="loading" @click="reset">关闭</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened1" :before-close="reset1">
        <el-form :model="form" label-width="120px" ref="form">
            <el-form-item label="图片ID" prop="image_id" :rules="[{required: true, message: '请选择图片id'}]">
                <el-select v-model="form.image_id" :disabled="loading" filterable>
                    <template v-for="image in $root.image_list">
                        <el-option v-if="image.status === 3 && image.module === 5" :value="image._id"
                                   :label="image.id"></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item label="上线时间" prop="online_date" :rules="[{required: true, message: '请选择上线时间'}]">
                <el-date-picker v-model="form.online_date" :disabled="loading"
                                value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item :label="`${k}描述`" v-for="(_, k) in form.i18n_desc" :prop="`i18n_desc.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}描述`}]:null">
                <el-input v-model="form.i18n_desc[k]" :disabled="loading"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :disabled="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :disabled="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {VueDraggableNext} from 'vue-draggable-next'
import {deepcopy, update} from "../../libs/utils";

export default {
    name: "CollectionEventImage",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false,
            dialog_opened1: false, editing1: null,
            content_tag: [], style_tag: [], category_list: [],
            image_status_enum: {0: '处理中', 1: '测试中', 2: '未通过', 3: '已通过', 4: '处理失败'},
            image_module_enum: {0: '图库', 1: '发现页', 2: 'Bonus', 3: 'IP', 4: 'DAILY'},
            image_tag_enum: {
                1: 'Special',
                2: 'Wallpaper',
                3: 'Collection',
                4: 'Artist',
                5: 'Music',
                6: 'Magic',
                7: 'Hot'
            },
            image_type_enum: {5: '普通', 20: '问号', 21: '黑白'},
            form: {
                image_id: '',
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                i18n_desc: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                online_date: null,
            },
        }
    },
    methods: {
        init() {
        },
        openDialog(event) {
            this.dialog_opened = this.loading = true;
            this.editing = event;
            axios.get(`/cms/v1/collection-event/${event.id}/image`).then(res => {
                this.data = res.data.data.imageList;
                this.loading = false;
            });
        },
        reset() {
            this.dialog_opened = this.loading = false;
            this.editing.count = this.data.length;
            this.editing = null;
        },
        openDialog1(item) {
            this.dialog_opened1 = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing1 = item;
                    update(this.form, item);
                    this.$root.image_list.forEach(i => {
                        if (i._id === this.editing1.image_id) {
                            this.form.online_date = i.online_date;
                        }
                    })
                }
            })
        },
        reset1() {
            this.$refs.form.resetFields();
            this.dialog_opened1 = this.loading = false;
            this.editing1 = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing1) {
                        const data = deepcopy(this.form);
                        data.r_id = this.editing1.id;
                        axios.put(`/cms/v1/collection-event/${this.editing.id}/image`, data).then(res => {
                            update(this.editing1, res.data.data);
                            this.$root.image_list.forEach(i => {
                                if (i._id === this.editing1.image_id) {
                                    i.online_date = this.form.online_date;
                                }
                            })
                            this.reset1();
                        });
                    } else {
                        axios.post(`/cms/v1/collection-event/${this.editing.id}/image`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.$root.image_list.forEach(i => {
                                if (i.id === this.form.image_id) {
                                    i.online_date = this.form.online_date;
                                }
                            })
                            this.resort();
                            this.reset1();
                        })
                    }
                }
            })
        },
        removeImage(r) {
            this.loading = true;
            axios.delete(`/cms/v1/collection-event/${this.editing.id}/image`, {data: {r_id: r.id}}).then(res => {
                this.data.splice(this.data.indexOf(r), 1);
                this.loading = false;
            })
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id)
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/collection-event/image`, {sort}).then(res => {
                this.loading = false;
            });
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>
.handle {
    width: 140px;
    margin: 5px;
    position: relative;
    display: inline-block;
}
</style>