<template>
    <div style="display: flex;justify-content: space-between">
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建一行</el-button>
        <div>
            <el-select v-model="headers.timezone" placeholder="时区" filterable allow-create style="width: 120px"
                       :disabled="loading" @change="init">
                <el-option value="Asia/Shanghai"></el-option>
                <el-option value="UTC"></el-option>
                <el-option value="America/Los_Angeles"></el-option>
            </el-select>
            <el-select v-model="headers.appVersion" placeholder="版本" filterable allow-create style="width: 120px"
                       :disabled="loading" @change="init">
                <el-option label="版本：2.0.0" value="2.0.0">2.0.0</el-option>
            </el-select>
        </div>
    </div>
    <el-card shadow="never" v-for="(d, i) in data" style="margin-top: 10px"
             :body-style="{backgroundColor: 'var(--el-fill-color)'}">
        <template #header>
            <div class="card-header">
                <div>
                    <span class="title">{{ d.i18n_name[headers.language] }}</span>
                    <span> (行ID: {{ d.id }}, 显示风格：{{ discovery_style_enum[d.style] }})</span>
                </div>
                <div>
                    <el-button type="success" circle icon="Top" :loading="loading" size="small"
                               :disabled="i===0" @click="moveUp(i)"></el-button>
                    <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                               :disabled="i===data.length - 1" @click="moveDown(i)"></el-button>
                    <el-button text type="info" size="small" :disabled="loading" @click="openDialog(d)">编辑</el-button>
                    <el-button text type="success" size="small" :disabled="loading" style="margin-left: 0"
                               @click="$refs.content.openDialog(d)" v-if="d.style!==0">内容
                    </el-button>
                    <el-switch size="small" v-model="d.active" :inactive-value="false" :active-value="true"
                               @change="update(d)" :disabled="loading"></el-switch>
                </div>
            </div>
        </template>
        <div class="daily" v-if="d.style === 0 && d.data && d.data.length">
            <div v-for="image in d.data" style="margin: 5px;position: relative;">
                <el-image :src="image.isToday?image.square||image.thumbnail:image.thumbnail"
                          :style="{width: image.isToday?'160px':'90px'}" fit="cover"></el-image>
                <el-tag size="small" class="daily-tag" round :effect="image.isToday?'dark':'plain'">
                    {{ image.isToday ? 'Today' : image.date.slice(5) }}
                </el-tag>
            </div>
        </div>
        <div class="collection" v-else-if="d.style === 1 && d.data && d.data.length">
            <div class="wrapper" v-for="collection in d.data">
                <el-image :src="collection.cover" fit="cover" style="height: 160px"></el-image>
                <div class="cover"
                     :style="{backgroundImage: `linear-gradient(to top, ${collection.color}, ${collection.color} 20%, transparent 50%)`}"></div>
                <el-tag class="collection-tag" round effect="dark">
                    <el-icon>
                        <Picture/>
                    </el-icon>
                    {{ collection.imageIds.length }}
                </el-tag>
                <el-tag class="collection-tag" round effect="plain" v-if="collection.price" size="small">
                    <el-icon>
                        <Coin/>
                    </el-icon>
                    <span>{{ collection.discounted || collection.price }}&nbsp;</span>
                    <span v-if="collection.discounted" style="text-decoration: line-through;color: #A8ABB2">
                        {{ collection.price }}</span>
                </el-tag>
            </div>
        </div>
        <div class="pre-gallery" v-else-if="d.style === 2 && d.data" :style="{backgroundColor: d.data.color}">
            <div style="height: 60px;display: flex;">
                <el-image :src="d.data.cover" style="width: 50px;height: 50px;margin: 5px"></el-image>
                <div style="position: relative;color: #FFF">
                    <p style="font-size: 18px;line-height: 40px;margin: 0;">{{ d.data.name }}</p>
                    <el-tag class="gallery-tag" round effect="dark" size="small" style="top: 36px">
                        <el-icon>
                            <Picture/>
                        </el-icon>
                        {{ d.data.imageIds.length }}
                    </el-tag>
                </div>
            </div>
            <div class="gallery" style="height: 180px">
                <div class="wrapper" v-for="image in d.data.imageList">
                    <el-image :src="image.thumbnail" style="height: 160px" lazy></el-image>
                </div>
            </div>
        </div>
        <div class="jigsaw" v-else-if="d.style === 4 && d.data && d.data.length">
            <div class="wrapper" v-for="jigsaw in d.data">
                <el-image :src="jigsaw.cover" fit="cover" style="height: 160px"></el-image>
                <div class="cover"
                     :style="{backgroundImage: `linear-gradient(to top, ${jigsaw.color}, ${jigsaw.color} 20%, transparent 50%)`}"></div>
                <el-tag class="jigsaw-tag" round effect="dark">
                    <el-icon>
                        <Picture/>
                    </el-icon>
                    {{ jigsaw.imageIds.length }}
                </el-tag>
            </div>
        </div>
        <div class="level" v-else-if="d.style === 5 && d.data && d.data.length">
            <div class="wrapper" v-for="level in d.data">
                <el-image :src="level.cover" fit="cover" style="height: 160px"></el-image>
                <div class="cover"
                     :style="{backgroundImage: `linear-gradient(to top, ${level.color}, ${level.color} 20%, transparent 50%)`}"></div>
            </div>
        </div>
        <div class="collection-event" v-else-if="d.style === 6 && d.data && d.data.length">
            <div class="wrapper" v-for="event in d.data">
                <el-image :src="event.cover" fit="cover" style="height: 160px"></el-image>
                <div class="cover"
                     :style="{backgroundImage: `linear-gradient(to top, ${event.color}, ${event.color} 20%, transparent 50%)`}"></div>
                <el-tag class="event-tag" round effect="dark">
                    <el-icon>
                        <Picture/>
                    </el-icon>
                    {{ event.imageIds.length }}
                </el-tag>
            </div>
        </div>
    </el-card>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="120px" :model="form">
            <el-form-item label="显示风格" prop="style" :rules="[{required: true, message: '请选择显示风格'}]">
                <el-select v-model="form.style" :disabled="loading || editing">
                    <el-option v-for="(v, k) in discovery_style_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null" v-if="form.style!==2">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <content ref="content" @close="init"></content>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";
import {VueDraggableNext} from 'vue-draggable-next'
import Content from "./Content";

export default {
    name: "DiscoveryView",
    components: {Content, VueDraggableNext},
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, gallery_list: [],
            headers: {
                language: 'EN',
                timezone: 'America/Los_Angeles',
                appVersion: '2.0.0',
                packageName: 'color.by.number.coloring.paint.puzzle.pixel.art.drawing.painting.games.kids.adults.fun.pictures',
            },
            discovery_style_enum: {
                0: 'DAILY',
                1: 'COLLECTION',
                2: 'GALLERY',
                3: 'STORY',
                4: 'JIGSAW',
                5: 'LEVEL',
                6: 'COLLECTION_EVENT',
                7: 'RECOMMEND_COLLECTION',
            },
            image_tag_enum: {
                1: 'Special',
                2: 'Wallpaper',
                3: 'Collection',
                4: 'Artist',
                5: 'Music',
                6: 'Magic',
                7: 'Hot'
            },
            form: {
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                style: null,
                active: false,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/discovery/row').then(res => {
                    this.data = res.data.data.rowList;
                }),
                axios.get(`/cms/v1/gallery`).then(res => {
                    this.gallery_list = res.data.data.galleryList;
                })
            ]).then(_ => {
                this.loading = false;
                this.loadAPI();
            })
        },
        loadAPI() {
            this.loading = true;
            const requests = [];
            this.data.forEach(d => {
                d.skip = 0
                d.data = [];
                if (d.style === 0) {
                    requests.push(this.loadDailyAPI(d))
                } else if (d.style === 1) {
                    requests.push(this.loadCollectionAPI(d));
                } else if (d.style === 2) {
                    d.data = d.gallery_id = null;
                    requests.push(this.loadGalleryAPI(d));
                } else if (d.style === 4) {
                    requests.push(this.loadJigsawAPI(d));
                } else if (d.style === 5) {
                    requests.push(this.loadLevelAPI(d));
                } else if (d.style === 6) {
                    requests.push(this.loadCollectionEventAPI(d));
                }
            });
            axios.all(requests).then(_ => {
                this.loading = false;
            });
        },
        loadDailyAPI(row) {
            const params = {skip: row.skip, limit: 20};
            return axios.get('/api/v1/discovery/daily', {params, headers: this.headers}).then(res => {
                row.data = row.data.concat(res.data.data.imageList);
                row.skip = res.data.data.skip;
            });
        },
        loadDailyMoreAPI(row) {
            row.skip += 20
            return this.loadDailyAPI(row);
        },
        loadCollectionAPI(row) {
            const params = {skip: row.skip, limit: 20, rowId: row.id};
            return axios.get('/api/v1/discovery/collection', {params, headers: this.headers}).then(res => {
                row.data = row.data.concat(res.data.data.collectionList);
                row.skip = res.data.data.skip;
            });
        },
        loadJigsawAPI(row) {
            const params = {skip: row.skip, limit: 20, rowId: row.id};
            return axios.get('/api/v1/discovery/jigsaw', {params, headers: this.headers}).then(res => {
                row.data = row.data.concat(res.data.data.jigsawList);
                row.skip = res.data.data.skip;
            });
        },
        loadLevelAPI(row) {
            const params = {skip: row.skip, limit: 20, rowId: row.id};
            return axios.get('/api/v1/discovery/level', {params, headers: this.headers}).then(res => {
                row.data = row.data.concat(res.data.data.levelList);
                row.skip = res.data.data.skip;
            });
        },
        loadGalleryAPI(row) {
            const params = {skip: row.skip, limit: 20, rowId: row.id};
            if (row.gallery_id) {
                return axios.get(`/api/v1/gallery/${row.gallery_id}/image`, {
                    params,
                    headers: this.headers
                }).then(res => {
                    row.data.imageList = row.data.concat(res.data.data.imageList);
                    row.skip = res.data.data.skip;
                });
            } else {
                axios.get(`/api/v1/discovery/gallery`, {params, headers: this.headers}).then(res => {
                    row.data = res.data.data;
                    if (row.data) {
                        row.skip = res.data.data.skip;
                        row.gallery_id = res.data.data.id;
                    }
                });
            }
        },
        loadCollectionEventAPI(row) {
            const params = {skip: row.skip, limit: 20, rowId: row.id};
            return axios.get('/api/v1/discovery/collection-event', {params, headers: this.headers}).then(res => {
                row.data = row.data.concat(res.data.data.collectionEventList);
                row.skip = res.data.data.skip;
            });
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/discovery/row/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.editing = null;
            this.dialog_opened = this.loading = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/discovery/row/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/discovery/row`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/discovery/row`, {sort}).then(_ => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.card-header {
    display: flex;
    justify-content: space-between;
    color: var(--el-text-color-secondary);
    font-size: 14px;
    line-height: 24px;
}

.card-header .title {
    font-size: 18px;
    color: var(--el-text-color-primary);
}

.el-card .daily, .el-card .collection, .el-card .gallery, .el-card .jigsaw, .el-card .level, .el-card .collection-event {
    display: flex;
    overflow-x: auto;
}

.el-card .daily .el-image {
    flex-shrink: 0;
    height: 160px;
    width: 90px;
    padding: 5px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: var(--el-box-shadow-light);
}

.el-card .daily .daily-tag {
    position: absolute;
    top: 3px;
    right: 3px;
    border-color: #9900FF;
    box-shadow: var(--el-box-shadow-light);
}

.daily-tag.el-tag--dark {
    background-color: #9900FF;
}

.daily-tag.el-tag--plain {
    color: #9900FF;
    background-color: #FFF;
}

.el-card .collection .wrapper, .el-card .jigsaw .wrapper, .el-card .jigsaw .wrapper, .el-card .collection-event .wrapper {
    height: 200px;
    width: 160px;
    border-radius: 10px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    margin: 0 5px;
}

.el-card .level .wrapper {
    height: 200px;
    border-radius: 10px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    margin: 0 5px;
}

.el-card .collection .cover, .el-card .jigsaw .cover, .el-card .level .cover, .el-card .collection-event .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.collection-tag.el-tag--plain, .jigsaw-tag.el-tag--plain {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #FFF;
    border-color: #FFF;
    color: #303133;
    font-size: 12px;
}

.collection-tag.el-tag--dark, .jigsaw-tag.el-tag--plain {
    position: absolute;
    left: 5px;
    bottom: 5px;
    background-color: #303133;
    border-color: #303133;
    color: #F5F7Fa
}

.el-card .pre-gallery {
    border-radius: 10px;
    padding: 5px;
}

.el-card .gallery .wrapper {
    border-radius: 10px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    margin: 5px;
}

.gallery .wrapper .el-image {
    padding: 5px;
    background-color: #FFF;
}

.gallery-tag.el-tag--dark {
    position: absolute;
    background-color: #303133;
    border-color: #303133;
    color: #F5F7Fa
}

</style>