<template>
    <div style="margin-left: 30px">
        <div>
            <el-button type="primary" :loading="loading" @click="openDialog()">创建新Bonus</el-button>
        </div>
        <el-table style="margin-top: 10px" :data="data" v-loading="loading">
            <el-table-column width="150" prop="id" label="ID"></el-table-column>
            <el-table-column width="300" prop="content" label="奖励内容"></el-table-column>
            <el-table-column width="100" prop="url" label="生成链接">
                <template #default="scope">
                    <el-button size="small" text type="primary" @click="copy(scope.row.url)">复制</el-button>
                </template>
            </el-table-column>
            <el-table-column width="200" prop="c_time" label="创建时间">
                <template #default="scope">
                    {{ timestampToDatetime(scope.row.c_time) }}
                </template>
            </el-table-column>
            <el-table-column width="150" label="操作">
                <template #default="scope">
                    <el-button size="small" type="danger" :loading="loading" @click="remove(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="resetForm">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="类型">
                <el-radio-group v-model="mode">
                    <el-radio label="image">发图片</el-radio>
                    <el-radio label="goods">发道具</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="图片id" prop="image_id" v-if="mode === 'image'">
                <el-select v-model="form.image_id" filterable :disabled="loading">
                    <el-option v-for="m in $root.image_list" :value="m.id">
                        {{ m.id }}({{ image_status_enum[m.status] }})
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="钻石" prop="diamond" v-if="mode === 'goods'">
                <el-input-number v-model="form.diamond" :min="0" :max="1000"></el-input-number>
            </el-form-item>
            <el-form-item label="提示" prop="hint" v-if="mode === 'goods'">
                <el-input-number v-model="form.hint" :min="0" :max="10"></el-input-number>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button text type="primary" :loading="loading" @click="resetForm">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {timestampToDatetime, generateUuid} from "../../libs/utils";
import axios from "ts-axios-new";

export default {
    name: "Bonus",
    data() {
        return {
            loading: false, data: [], dialog_opened: false, mode: 'image',
            image_status_enum: {0: '处理中', 1: '测试中', 2: '未通过', 3: '已通过', 4: '处理失败'},
            form: {
                image_id: null,
                diamond: 0,
                hint: 0,
            },
        }
    },
    methods: {
        timestampToDatetime(timestamp) {
            return timestampToDatetime(timestamp)
        },
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/bonus`).then(res => {
                    this.data = res.data.data.bonusList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog() {
            this.dialog_opened = true;
        },
        resetForm() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid && (this.form.image_id || this.form.diamond || this.form.hint)) {
                    this.loading = true;
                    let content;
                    if (this.mode === 'image') {
                        content = `imageId=${this.form.image_id}`
                    } else if (this.mode === 'goods') {
                        content = `id=${generateUuid()}`;
                        if (this.form.diamond) {
                            content += `,diamond=${this.form.diamond}`;
                        }
                        if (this.form.hint) {
                            content += `,hint=${this.form.hint}`;
                        }
                    }
                    axios.post(`/cms/v1/sign`, {text: content}).then(res => {
                        const deeplink = `app://neptune-pluto-color-master/bonus/${this.mode}?content=${content}&signature=${res.data.data.signature}`
                        const form = {
                            content,
                            url: `https://www.rabigame.com/colormaster/landing/index.html?deeplink=${encodeURIComponent(deeplink)}`,
                        }
                        axios.post(`/cms/v1/bonus`, form).then(res => {
                            this.data.unshift(res.data.data);
                            this.resetForm();
                        })
                    });
                }
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`/cms/v1/bonus/${row.id}`).then(_ => {
                this.data.splice(this.data.indexOf(row), 1);
                this.loading = false;
            })
        },
        copy(text) {
            clipboard.writeText(text);
            this.$notify.success('复制成功！');
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>