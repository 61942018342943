<template>
    <el-container style="height: 100%" v-loading="loading">
        <el-aside width="200px" style="height: 100%;background-color: var(--el-fill-color-darker)">
            <el-scrollbar>
                <div class="aside-group">
                    <div style="display: flex;justify-content: space-between">
                        <b>内容标签</b>
                        <el-button type="success" text size="small" v-if="content_editing"
                                   @click="content_editing=false">
                            完成
                        </el-button>
                        <el-button type="info" text size="small" v-else @click="content_editing=true">编辑</el-button>
                    </div>
                    <el-tree :data="content_tag" node-key="name" show-checkbox ref="content" default-expand-all
                             @check-change="checkChange" :expand-on-click-node="false" check-on-click-node>
                        <template #default="{node, data}">
                            <div class="tree-node">
                                <span>{{ data.name }}</span>
                                <el-icon @click.stop="createTag('content', data)" v-if="content_editing">
                                    <Plus/>
                                </el-icon>
                                <span v-else>{{ data.images ? data.images.length : 0 }}</span>
                            </div>
                        </template>
                    </el-tree>
                </div>
                <div class="aside-group">
                    <div style="display: flex;justify-content: space-between">
                        <b>风格标签</b>
                        <el-button type="success" text size="small" v-if="style_editing" @click="style_editing=false">
                            完成
                        </el-button>
                        <el-button type="info" text size="small" v-else @click="style_editing=true">编辑</el-button>
                    </div>
                    <el-tree :data="style_tags" node-key="name" show-checkbox ref="style" default-expand-all
                             @check-change="checkChange" :expand-on-click-node="false" check-on-click-node>
                        <template #default="{node, data}">
                            <div class="tree-node">
                                <span>{{ data.name }}</span>
                                <el-icon @click.stop="createTag('style', data)" v-if="style_editing">
                                    <Plus/>
                                </el-icon>
                                <span v-else>{{ data.images ? data.images.length : 0 }}</span>
                            </div>
                        </template>
                    </el-tree>
                </div>
                <div class="aside-group">
                    <b>难度标签</b>
                    <el-tree :data="difficulty_tag" node-key="name" show-checkbox ref="difficulty" default-expand-all
                             @check-change="checkChange" :expand-on-click-node="false" check-on-click-node>
                        <template #default="{node, data}">
                            <div class="tree-node">
                                <span>L{{ data.name }}</span>
                                <span>{{ data.images ? data.images.length : 0 }}</span>
                            </div>
                        </template>
                    </el-tree>
                </div>
            </el-scrollbar>
        </el-aside>
        <el-main style="height: 100%;padding: 0">
            <el-container style="height: 100%">
                <el-header height="40px"
                           style="background-color: var(--el-fill-color-darker);display: flex;justify-content: space-between">
                    <el-checkbox-group v-model="filters">
                        <el-checkbox label="online">已上线</el-checkbox>
                        <el-checkbox label="offline">未上线</el-checkbox>
                        <el-checkbox label="own">自有</el-checkbox>
                        <el-checkbox label="special">special</el-checkbox>
                        <!--                        <el-checkbox label="score">有得分</el-checkbox>-->
                    </el-checkbox-group>
                    <el-input v-model="query" placeholder="ID搜索" size="small"
                              style="width: 200px;margin: 6px"></el-input>
                </el-header>
                <el-main style="height: 100%;padding: 10px">
                    <el-scrollbar>
                        <div style="display: flex;flex-wrap: wrap">
                            <el-card :body-style="{padding: 0}" shadow="hover" v-for="d in dataPage"
                                     style="margin: 10px;height: 400px;width: 240px;font-size: 12px">
                                <el-image style="height: 120px;width: 120px" lazy
                                          :src="`https://color-cdn.rabigame.com/${d.thumbnail}`"></el-image>
                                <el-image style="height: 120px;width: 120px" lazy
                                          :src="`https://color-cdn.rabigame.com/material/${d.key}/finished.webp`"></el-image>
                                <div style="padding: 10px;line-height: 34px">
                                    <el-row style="display: flex;justify-content: space-between">
                                        <span>ID: {{ d.id }}</span>
                                        <el-select v-model="d.author" size="small" style="width: 100px;margin: 3px 0"
                                                   allow-create filterable placeholder="作者" :disload="loading"
                                                   @change="update(d)">
                                            <el-option v-for="a in author_list" :value="a"></el-option>
                                        </el-select>
                                    </el-row>
                                    <el-row style="display: flex;justify-content: space-between">
                                        <span>上线时间：{{ d.online_date || '未上线' }}</span>
                                    </el-row>
                                    <el-row style="line-height: 24px; margin-top: 5px">分类：
                                        <el-select size="small" v-model="d.category_list" @change="update(d)"
                                                   filterable style="width: 180px" multiple :disabled="loading">
                                            <el-option v-for="c in category_list" :label="c.name"
                                                       :value="c.id"></el-option>
                                        </el-select>
                                    </el-row>
                                    <el-row style="line-height: 24px; margin-top: 5px">主要内容：
                                        <el-tag v-for="t in d.major_tags" :closable="!loading"
                                                @close="removeTag(d, 'major_tags', t)">{{ t }}
                                        </el-tag>
                                        <el-popover trigger="click" v-if="!d.major_tags">
                                            <el-cascader size="small" :options="content_tag" filterable
                                                         :props="{expandTrigger: 'hover', label: 'name', value: 'name'}"
                                                         @change="addTag($event, d, 'major_tags')"></el-cascader>
                                            <template #reference>
                                                <el-button size="small" type="primary" text>添加
                                                </el-button>
                                            </template>
                                        </el-popover>
                                    </el-row>
                                    <el-row style="line-height: 24px; margin-top: 5px">次要内容：
                                        <el-tag v-for="t in d.minor_tags" :closable="!loading"
                                                @close="removeTag(d, 'minor_tags', t)">{{ t }}
                                        </el-tag>
                                        <el-popover trigger="click">
                                            <el-cascader size="small" :options="content_tag" filterable
                                                         :props="{expandTrigger: 'hover', label: 'name', value: 'name'}"
                                                         @change="addTag($event, d, 'minor_tags')"></el-cascader>
                                            <template #reference>
                                                <el-button size="small" type="primary" text>添加
                                                </el-button>
                                            </template>
                                        </el-popover>
                                    </el-row>
                                    <el-row style="line-height: 24px; margin-top: 5px">风格标签：
                                        <el-tag v-for="t in d.style_tags" :closable="!loading"
                                                @close="removeTag(d, 'style_tags', t)">{{ t }}
                                        </el-tag>
                                        <el-popover trigger="click">
                                            <el-cascader size="small" :options="style_tags" filterable
                                                         :props="{expandTrigger: 'hover', label: 'name', value: 'name'}"
                                                         @change="addTag($event, d, 'style_tags')"></el-cascader>
                                            <template #reference>
                                                <el-button size="small" type="primary" text>添加
                                                </el-button>
                                            </template>
                                        </el-popover>
                                    </el-row>
                                    <el-row style="line-height: 24px; margin-top: 5px;">难度：
                                        <span v-if="d.difficulty"
                                              style="color: var(--el-color-error)">L{{ d.difficulty }}</span>
                                        <span v-else>
                                            L{{ d.cells > 800 ? 4 : d.cells > 500 ? 3 : d.cells > 300 ? 2 : 1 }}
                                        </span>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>
                    </el-scrollbar>
                </el-main>
                <el-footer style="text-align: center" height="40px">
                    <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                                   :total="dataFilter.length"></el-pagination>
                </el-footer>
            </el-container>
        </el-main>
    </el-container>
</template>

<script>
import axios from "ts-axios-new";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Tag",
    data() {
        return {
            loading: false, data: [], content_tag: [], style_tags: [], category_list: [],
            difficulty_tag: [
                {name: 1, images: []}, {name: 2, images: []}, {name: 3, images: []}, {name: 4, images: []}],
            filters: [], sort: 'online', order: 'desc', page: 1, size: 20,
            content_keys: [], style_keys: [], difficulty_keys: [],
            content_editing: false, style_editing: false,
            query: '',
            author_list: [],
            recommend: {
                main_score: 15,
                secondary_score: 8,
                style_score: 3,
            },
            dialog_opened: false,
            recommend_list: [],
            editing: null,
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/tag/content`).then(res => {
                    this.content_tag = res.data.data.tagList;
                }),
                axios.get(`/cms/v1/tag/style`).then(res => {
                    this.style_tags = res.data.data.tagList;
                }),
                axios.get('/cms/v1/category').then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
            ]).then(_ => {
                this.author_list = [];
                this.data = this.$root.image_list;
                this.data.forEach(d => {
                    d.authors.forEach(a => {
                        if (!this.author_list.includes(a)) {
                            this.author_list.push(a);
                        }
                    })
                    if (d.major_tags) {
                        d.major_tags.forEach(t => {
                            this.content_tag.forEach(c => {
                                this.df(c, d.id, t);
                            })
                        })
                    }
                    if (d.minor_tags) {
                        d.minor_tags.forEach(t => {
                            this.content_tag.forEach(c => {
                                this.df(c, d.id, t);
                            })
                        })
                    }
                    if (d.style_tags) {
                        d.style_tags.forEach(t => {
                            this.style_tags.forEach(c => {
                                this.df(c, d.id, t);
                            })
                        })
                    }
                    const difficulty = d.difficulty ? d.difficulty : d.cells > 800 ? 4 : d.cells > 500 ? 3 : d.cells > 300 ? 2 : 1;
                    this.difficulty_tag[difficulty - 1].images.push(d.id);
                })
                this.loading = false;
            })
        },
        df(tree, id, tag) {
            if (!tree.images) {
                tree.images = [];
            }
            if (tree.name === tag && !tree.images.includes(id)) {
                tree.images.push(id);
                return true;
            } else if (tree.children) {
                for (let i = 0; i < tree.children.length; i++) {
                    const c = tree.children[i];
                    if (this.df(c, id, tag)) {
                        if (!tree.images.includes(id)) {
                            tree.images.push(id);
                            return true;
                        }
                    }
                }
            }
        },
        resort(sort) {
            if (this.sort === sort) {
                if (this.order === 'desc') {
                    this.order = 'asc';
                } else {
                    this.sort = null;
                }
            } else {
                this.sort = sort;
                this.order = 'desc';
            }
        },
        checkChange() {
            this.content_keys = this.$refs.content.getCheckedKeys();
            this.style_keys = this.$refs.style.getCheckedKeys();
            this.difficulty_keys = this.$refs.difficulty.getCheckedKeys();
        },
        createTag(some, parent) {
            ElMessageBox.prompt(`请输入要在"${parent.name}"下添加的标签名称`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                inputPattern: /.+/,
                inputErrorMessage: '请输入名称',
            }).then(({value}) => {
                axios.post(`/cms/v1/tag/${some}`, {name: value, parent_id: parent.id}).then(res => {
                    res.data.data.children = [];
                    parent.children.push(res.data.data);
                });
            }).catch(_ => {
            })
        },
        addTag(value, d, category) {
            if (d[category]) {
                d[category].push(value[value.length - 1]);
            } else {
                d[category] = [value[value.length - 1]];
            }
            this.update(d);
        },
        removeTag(d, category, tag) {
            d[category].splice(d[category].indexOf(tag), 1);
            if (d[category].length === 0) {
                d[category] = null;
            }
            this.update(d);
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/image/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                if (this.query && !(d.id + '').includes(this.query))
                    return false;
                if ((!this.filters.includes('online') || d.online_date) && (!this.filters.includes('offline') || !d.online_date) && (!this.filters.includes('own') || ([5, 20, 21].includes(d.image_type))) && (!this.filters.includes('special') || d.level === 2)) {
                    if (this.content_keys.length > 0) {
                        let valid = false;
                        this.content_keys.forEach(c => {
                            if (d.major_tags && d.major_tags.includes(c)) {
                                valid = true;
                            }
                            if (d.minor_tags && d.minor_tags.includes(c)) {
                                valid = true;
                            }
                        });
                        if (!valid)
                            return false;
                    }
                    if (this.style_keys.length > 0) {
                        let valid = false;
                        this.style_keys.forEach(s => {
                            if (d.style_tags && d.style_tags.includes(s)) {
                                valid = true;
                            }
                        });
                        if (!valid)
                            return false;
                    }
                    if (this.difficulty_keys.length > 0) {
                        let valid = false;
                        this.difficulty_keys.forEach(k => {
                            if (d.difficulty === k || d.cells > 800 && k === 4 || d.cells > 500 && d.cells <= 800 && k === 3 || d.cells <= 500 && d.cells > 300 && k === 2 || d.cells <= 300 && k === 1) {
                                valid = true;
                            }
                        })
                        if (!valid)
                            return false;
                    }
                    return true;
                }
                return false;
            });
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size)
        }
    },
}
</script>

<style scoped>
.aside-group {
    color: var(--el-text-color-regular);
    padding: 16px 0 8px 8px;
    font-size: 12px;
}

.el-tree {
    background-color: var(--el-fill-color-darker);
}

.tree-node {
    color: var(--el-text-color-regular);
    padding: 3px 10px 3px 0;
    font-size: 12px;
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.el-tree ::v-deep(.el-tree-node__label) {
    width: 100%;
}
</style>