<template>
    <el-table v-loading="loading" :data="data">
        <el-table-column label="用户" prop="email" width="120" show-overflow-tooltip>
            <template #default="scope">
                <img :src="scope.row.avatar" style="width: 25px;vertical-align: middle"> {{scope.row.name}}
            </template>
        </el-table-column>
        <el-table-column label="权限">
            <template #default="scope">
                <el-checkbox-group v-model="scope.row.access" :disabled="loading" @change="update(scope.row)">
                    <el-checkbox label="write">上传/编辑素材</el-checkbox>
                    <el-checkbox label="app">编辑app内容</el-checkbox>
                    <el-checkbox label="auth">权限管理</el-checkbox>
                </el-checkbox-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import axios from "ts-axios-new";

export default {
    name: "Auth",
    data() {
        return {
            loading: false, data: [],
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/user').then(res => {
                this.data = res.data.data.userList;
                this.data.forEach(d => {
                    if (!d.access) {
                        d.access = [];
                    }
                })
                this.loading = false;
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/user/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>