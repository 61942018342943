<template>
    <div>
        <el-button :disabled="loading" type="primary" @click="openDialog()">新建</el-button>
    </div>
    <el-table v-loading="loading" :data="data" style="margin-top: 20px">
        <el-table-column label="封面" width="150" prop="cover">
            <template #default="scope">
                <el-image style="width: 140px" lazy
                          :src="`https://color-cdn.rabigame.com/${scope.row.cover}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="icon" width="100" prop="icon">
            <template #default="scope">
                <el-image style="width: 60px" lazy
                          :src="`https://color-cdn.rabigame.com/${scope.row.icon}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="活跃" width="100" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="主题色" width="100" prop="color"></el-table-column>
        <el-table-column label="名称" width="150" prop="i18n_name.EN"></el-table-column>
        <el-table-column label="描述" width="250" prop="i18n_desc.EN"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="success" :disabled="loading" @click="$refs.image.openDialog(scope.row)">
                    内容
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset" :close-on-click-modal="false">
        <el-form ref="form" :model="form" label-width="120px">
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item :label="`${k}描述`" v-for="(_, k) in form.i18n_desc" :prop="`i18n_desc.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}描述`}]:null">
                <el-input v-model="form.i18n_desc[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="主题色" prop="color" :rules="[{required: true, validator}]">
                <el-color-picker v-model="form.color" :disabled="loading"></el-color-picker>
                <el-input v-model="form.color" :disabled="loading"
                          style="width: 100px;display: inline-block;margin-left: 20px"></el-input>
            </el-form-item>
            <el-form-item label="封面" prop="cover" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'event'}" :onProgress="handleProgress">
                    <img v-if="form.cover" :src="`https://color-cdn.rabigame.com/${form.cover}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="彩色图标" prop="icon" :rules="[{required: true, message: '请上传icon'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleIcon"
                           accept="image/*" :data="{prefix: 'event'}" :onProgress="handleProgress">
                    <img v-if="form.icon" :src="`https://color-cdn.rabigame.com/${form.icon}`" style="width: 100px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="灰色图标" prop="gray_icon" :rules="[{required: true, message: '请上传icon'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleGray"
                           accept="image/*" :data="{prefix: 'event'}" :onProgress="handleProgress">
                    <img v-if="form.gray_icon" :src="`https://color-cdn.rabigame.com/${form.gray_icon}`"
                         style="width: 100px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="结束时间" prop="close" :rules="[{required: true, message: '请选择活动结束时间'}]">
                <el-date-picker v-model="form.close" value-format="YYYY-MM-DD" :disabled="loading"></el-date-picker>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <collection-event-image ref="image"></collection-event-image>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";
import CollectionEventImage from "./CollectionEventImage";

export default {
    name: "CollectionEvent",
    components: {CollectionEventImage},
    data() {
        return {
            loading: false, data: [], editing: false, dialog_opened: false,
            form: {
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                i18n_desc: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                cover: '',
                color: null,
                icon: '',
                gray_icon: '',
                close: null,
                active: false,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/collection-event').then(res => {
                this.data = res.data.data.eventList;
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/collection-event/${row.id}`, row).then(_ => {
                this.loading = false;
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
            this.form.i18n_desc = {
                EN: '',
                FR: '',
                HI: '',
                IT: '',
                AR: '',
                ES: '',
                PT: '',
                DE: '',
                EL: '',
                ZH_HANS: '',
                ZH_HANT: '',
                ID: '',
                KO: '',
                TH: '',
                NL: '',
                TR: '',
                PL: '',
                VI: '',
                FI: '',
                RU: '',
                HU: '',
                JA: '',
                NO: '',
                SV: '',
                UK: '',
                MS: '',
                IW: '',
                RO: '',
                TL: '',
            };
            this.form.i18n_name = {
                EN: '',
                FR: '',
                HI: '',
                IT: '',
                AR: '',
                ES: '',
                PT: '',
                DE: '',
                EL: '',
                ZH_HANS: '',
                ZH_HANT: '',
                ID: '',
                KO: '',
                TH: '',
                NL: '',
                TR: '',
                PL: '',
                VI: '',
                FI: '',
                RU: '',
                HU: '',
                JA: '',
                NO: '',
                SV: '',
                UK: '',
                MS: '',
                IW: '',
                RO: '',
                TL: '',
            }
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/collection-event/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/collection-event`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        });
                    }
                }
            })
        },
        validator(rule, value, callback) {
            if (/^#[A-Z0-9]{6}$/.test(value)) {
                callback();
            } else {
                callback('请选择或输入正确格式的颜色码');
            }
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.cover = res.data.name
        },
        handleIcon(res) {
            this.loading = false;
            this.form.icon = res.data.name
        },
        handleGray(res) {
            this.loading = false;
            this.form.gray_icon = res.data.name
        },
        handleProgress() {
            this.loading = true;
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>