<template>
    <el-dialog v-model="dialog_opened" :before-close="close">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="类型" prop="image_type" :rules="[{required: true, message: '请选择类型'}]">
                <el-radio-group v-model="form.image_type" :disabled="loading">
                    <el-radio v-for="(v, k) in image_type_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="[5, 20].includes(form.image_type)?'单色完成图':'Mask图'"
                          :rules="[{required: true, message: '请选择图片'}]">
                <el-upload action="/cms/v1/test/split" :auto-upload="false" ref="upload" :limit="1" :data="form"
                           accept="image/*" :on-success="onSuccess">
                    <template #trigger>
                        <el-button type="primary" :loading="loading">选择图片</el-button>
                    </template>
                </el-upload>
            </el-form-item>
            <el-form-item v-if="cells">
                不超过 {{ cells }} 个色块
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="close">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">上传</el-button>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "Cell",
    data() {
        return {
            loading: false, dialog_opened: false, cells: null,
            image_type_enum: {5: '普通单色', 6: '普通非单色', 20: '单色问号', 21: '非单色黑白', 30: '非单色GIF'},
            form: {
                image_type: 5,
            }
        }
    },
    methods: {
        open() {
            this.dialog_opened = true;
        },
        close() {
            if (this.loading)
                return
            this.$refs.upload.clearFiles();
            this.dialog_opened = false;
            this.cells = null;
        },
        submit() {
            this.loading = true;
            this.$refs.upload.submit();
        },
        onSuccess(res) {
            this.cells = res.data.cells;
            this.loading = false;
        },
    },
}
</script>

<style scoped>

</style>