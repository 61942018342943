<template>
    <el-calendar ref="calendar" v-loading="loading">
        <template #date-cell="{data: _data}">
            <div>{{ _data.day.split('-').slice(1).join('-') }}</div>
            <div style="height: 70px;" @click="openDialog(_data.day)">
                <el-image :src="`https://color-cdn.rabigame.com/${d.thumbnail}`" style="height: 30px;margin: 2px"
                          v-for="d in data.filter(d=>d.online_date===_data.day&&d.module===0).sort((a, b) => a.sort < b.sort ?-1:1)"></el-image>
            </div>
        </template>
    </el-calendar>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <vue-draggable-next v-model="selected" :animation="300" handle=".handle" @change="resort">
            <transition-group>
                <div class="handle" style="display: inline-block;position: relative" v-for="image in selected">
                    <el-image style="width: 140px;margin: 5px" lazy
                              :src="`https://color-cdn.rabigame.com/${image.thumbnail}`"></el-image>
                    <el-icon style="position: absolute; top: 5px; right: 5px;cursor: pointer;" @click="offline(image)">
                        <CircleClose/>
                    </el-icon>
                </div>
            </transition-group>
        </vue-draggable-next>
        <el-divider></el-divider>
        <el-input v-model="query" prefix-icon="Search"></el-input>
        <el-table :data="dataFilter" style="margin-top: 10px">
            <el-table-column label="预览图" prop="prefix" width="230">
                <template #default="scope">
                    <el-image lazy :src="`https://color-cdn.rabigame.com/${scope.row.thumbnail}`"
                              style="width: 100px"></el-image>
                    <el-image lazy :src="`https://color-cdn.rabigame.com/material/${scope.row.key}/finished.webp`"
                              style="width: 100px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="id" prop="id" width="60"></el-table-column>
            <el-table-column label="视频" prop="video" width="60">
                <template #default="scope">
                    <el-icon v-if="scope.row.video" style="color: var(--el-color-success)">
                        <CircleCheck/>
                    </el-icon>
                    <span v-else></span>
                </template>
            </el-table-column>
            <el-table-column label="类型" prop="type" width="60">
                <template #default="scope">
                    {{ image_type_enum[scope.row.type] }}
                </template>
            </el-table-column>
            <el-table-column label="色块" prop="cells" width="60"></el-table-column>
            <el-table-column label="难度" prop="difficulty" width="60">
                <template #default="scope">
                    <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                        L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="categories" width="60">
                <template #default="scope">
                    <template v-for="c in scope.row.categories">
                        <template v-for="cc in category_list">
                            <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="角标" prop="tags" width="100">
                <template #default="scope">
                    <el-tag size="small" v-for="t in scope.row.tags">
                        {{ image_tag_enum[t] }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="预计上线" prop="online_date" width="160"></el-table-column>
            <el-table-column label="激励视频" prop="video" width="60">
                <template #default="scope">
                    <el-icon style="cursor: pointer;" @click="updateVideo(scope.row)">
                        <CircleCheckFilled style="color: var(--el-color-success)" v-if="scope.row.video"/>
                        <CircleCheck v-else/>
                    </el-icon>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="scope">
                    <el-button type="primary" :disabled="form.date === scope.row.online_date"
                               @click="online(scope.row)">
                        {{ form.date === scope.row.online_date ? '已' : '' }}选择
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" @click="reset" :disabled="loading">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'
import {timestampToDate} from "../../libs/utils";

export default {
    name: "Update",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, category_list: [], selected: [], query: '',
            image_type_enum: {5: '普通', 20: '问号', 21: '黑白'}, today: timestampToDate(Date.now()),
            image_tag_enum: {
                1: 'Special',
                2: 'Wallpaper',
                3: 'Collection',
                4: 'Artist',
                5: 'Music',
                6: 'Magic',
                7: 'Hot'
            },
            form: {
                date: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            this.data = this.$root.image_list;
            axios.all([
                axios.get('/cms/v1/category').then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        reset() {
            this.loading = this.dialog_opened = false;
        },
        openDialog(date) {
            this.form.date = date;
            this.dialog_opened = true;
            this.query = '';
            this.selected = this.data.filter(d => d.online_date === date && [0, 5].includes(d.module)).sort(function (a, b) {
                return a.sort < b.sort ? -1 : 1
            });
        },
        online(image) {
            this.loading = true;
            image.online_date = this.form.date;
            axios.put(`/cms/v1/image/${image._id}`, image).then(res => {
                this.selected.push(image);
                this.loading = false;
            })
        },
        offline(image) {
            this.loading = true;
            image.online_date = null;
            axios.put(`/cms/v1/image/${image._id}`, image).then(res => {
                this.selected.splice(this.selected.indexOf(image), 1);
                this.loading = false;
            })
        },
        updateVideo(row) {
            this.loading = true;
            row.video = !row.video;
            axios.put(`/cms/v1/image/${row._id}`, row).then(res => {
                this.loading = false;
            });
        },
        resort() {
            const sort = [];
            this.selected.forEach((d, i) => {
                sort.push(d._id);
                d.sort = i;
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/image`, {sort}).then(res => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return d.status === 3 && [0, 5].includes(d.module) && (!d.online_date || d.online_date >= this.today) && (!this.query || (d.id + '').includes(this.query) || d.authors.includes(this.query));
            })
        },
    }
}
</script>

<style scoped>
.el-calendar ::v-deep(.el-calendar-day) {
    height: fit-content;
    width: 160px;
    min-height: var(--el-calendar-cell-width);
}
</style>