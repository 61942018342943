<template>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <vue-draggable-next v-model="data" :animation="300" handle=".handle" @change="sort">
            <transition-group>
                <template v-for="d in data">
                    <template v-for="image in $root.image_list">
                        <div class="handle" v-if="image._id === d.image_id">
                            <el-image style="width: 140px;margin: 5px" lazy
                                      :src="`https://color-cdn.rabigame.com/${image.thumbnail}`"></el-image>
                            <el-icon @click="removeImage(d)">
                                <CircleClose/>
                            </el-icon>
                        </div>
                    </template>
                </template>
            </transition-group>
        </vue-draggable-next>
        <el-divider></el-divider>
        <el-input v-model="query" prefix-icon="Search"></el-input>
        <el-table style="margin-top: 10px" v-loading="loading" :data="dataFilter">
            <el-table-column label="预览图" prop="prefix" width="230">
                <template #default="scope">
                    <el-image lazy :src="`https://color-cdn.rabigame.com/${scope.row.thumbnail}`"
                              style="width: 100px"></el-image>
                    <el-image lazy :src="`https://color-cdn.rabigame.com/material/${scope.row.key}/finished.webp`"
                              style="width: 100px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="id" prop="id" width="100"></el-table-column>
            <el-table-column label="类型" prop="type" width="60">
                <template #default="scope">
                    {{ image_type_enum[scope.row.type] }}
                </template>
            </el-table-column>
            <el-table-column label="色块" prop="cells" width="60"></el-table-column>
            <el-table-column label="难度" prop="difficulty" width="60">
                <template #default="scope">
                    <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                        L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="categories" width="60">
                <template #default="scope">
                    <template v-for="c in scope.row.categories">
                        <template v-for="cc in category_list">
                            <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="主要内容" prop="major_tags" width="100">
                <template #default="scope">
                    <el-tag size="small" v-for="t in scope.row.major_tags">{{ t }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="次要内容" prop="minor_tags" width="100">
                <template #default="scope">
                    <el-tag size="small" v-for="t in scope.row.minor_tags">{{ t }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="风格标签" prop="style_tags" width="100">
                <template #default="scope">
                    <el-tag size="small" v-for="t in scope.row.style_tags">{{ t }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="作者" prop="authors" width="100"></el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="scope">
                    <el-button type="primary" @click="addImage(scope.row)" :disabled="loading">添加</el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" :disabled="loading" @click="reset">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "GalleryImage",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false,
            content_tag: [], style_tag: [], category_list: [], query: '',
            image_status_enum: {0: '处理中', 1: '测试中', 2: '未通过', 3: '已通过', 4: '处理失败'},
            image_module_enum: {0: '图库', 1: '发现页', 2: 'Bonus', 3: 'IP', 4: 'DAILY'},
            image_tag_enum: {
                1: 'Special',
                2: 'Wallpaper',
                3: 'Collection',
                4: 'Artist',
                5: 'Music',
                6: 'Magic',
                7: 'Hot'
            },
            image_type_enum: {5: '普通', 20: '问号', 21: '黑白'},
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/category').then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
                axios.get(`/cms/v1/tag/content`).then(res => {
                    this.content_tag = res.data.data.tagList;
                }),
                axios.get(`/cms/v1/tag/style`).then(res => {
                    this.style_tag = res.data.data.tagList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(gallery) {
            this.dialog_opened = this.loading = true;
            this.editing = gallery;
            axios.get(`/cms/v1/gallery/${gallery.id}/image`).then(res => {
                this.data = res.data.data.imageList;
                this.loading = false;
            });
        },
        reset() {
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        addImage(image) {
            this.loading = true;
            axios.post(`/cms/v1/gallery/${this.editing.id}/image`, {image_id: image._id}).then(res => {
                this.data.unshift(res.data.data);
                this.loading = false;
                this.sort();
            });
        },
        removeImage(r) {
            this.loading = true;
            axios.delete(`/cms/v1/gallery/${this.editing.id}/image`, {data: {r_id: r.id}}).then(res => {
                this.data.splice(this.data.indexOf(r), 1);
                this.loading = false;
            })
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id)
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/gallery/image`, {sort}).then(res => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.$root.image_list.filter(d => {
                let valid = d.status === 3 && (d._id === this.query || d.id + '' === this.query);
                if (valid) {
                    this.data.forEach(dd => {
                        if (dd.image_id === d._id) {
                            valid = false;
                        }
                    })
                }
                return valid
            });
        }
    },
}
</script>

<style scoped>
.handle {
    display: inline-block;
    position: relative;
    font-size: 20px;
    color: var(--el-color-danger)
}

.handle .el-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
</style>