<template>
    <el-dialog v-model="dialog_opened" v-loading="loading" :before-close="close" width="80%">
        <div style="display: inline-flex">
            <date-range-picker v-model="form" :disabled="loading" style="width: 300px"></date-range-picker>
            <el-select v-model="form.country" filterable :disabled="loading" placeholder="全部国家" clearable>
                <el-option v-for="c in $root.country_list" :key="c.code" :value="c.ip_name"
                           :label="c.en_name + ' (' + c.code + ')'">
                    {{ c.en_name + ' (' + c.code + ')' }}
                </el-option>
            </el-select>
            <el-button type="primary" @click="load" :loading="loading">查询</el-button>
        </div>
        <downloadable-table title="集合数据" :data="data" v-loading="loading" style="margin-top: 10px"
                            :summary-method="summaryMethod" show-summary>
            <el-table-column label="ID" width="60" prop="id"></el-table-column>
            <el-table-column label="预览图" width="120" download-hide>
                <template #default="scope">
                    <el-image style="width: 100px" lazy
                              :src="`https://color-cdn.rabigame.com/${scope.row.thumbnail}`"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="完成图" width="120" download-hide>
                <template #default="scope">
                    <el-image style="width: 100px" lazy
                              :src="`https://color-cdn.rabigame.com/material/${scope.row.key}/finished.webp`"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="展示" prop="impression" width="100"></el-table-column>
            <el-table-column label="进入" prop="enter" width="100" sortable></el-table-column>
            <el-table-column label="进入率" prop="enter_rate" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.enter_rate }}%
                </template>
            </el-table-column>
            <el-table-column label="完成率" prop="finish_rate" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.finish_rate }}%
                </template>
            </el-table-column>
            <el-table-column label="转化率" prop="share_rate" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.share_rate }}%
                </template>
            </el-table-column>
        </downloadable-table>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="close">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {timestampToDate} from "../../libs/utils";
import DateRangePicker from "../../base/DateRangePicker";
import DownloadableTable from "../../base/DownloadableTable";

export default {
    name: "CollectionStat",
    components: {DownloadableTable, DateRangePicker},
    data() {
        const now = Date.now();
        return {
            dialog_opened: false, loading: false, collection: null, data: [],
            form: {
                start: timestampToDate(now - 6 * 24 * 3600 * 1000),
                end: timestampToDate(now - 24 * 3600 * 1000),
                country: '',
            },
        }
    },
    methods: {
        init(collection) {
            this.collection = collection;
            this.dialog_opened = true;
            this.load();
        },
        close() {
            this.dialog_opened = this.loading = false;
            this.collection = null;
        },
        load() {
            this.loading = true;
            axios.get(`/cms/v1/collection/${this.collection.id}/stat`, {params: this.form}).then(res => {
                this.data = res.data.data.statList;
                this.data.forEach(d => {
                    d.enter_rate = d.impression ? parseInt(d.enter / d.impression * 10000) / 100.0 : null;
                    d.finish_rate = parseInt(d.finish / d.enter * 10000) / 100.0 || 0;
                    d.share_rate = parseInt(d.share / d.finish * 10000) / 100.0 || 0;
                });
                this.loading = false;
            });
        },
        summaryMethod({columns, data}) {
            const summary = [];
            let enter = 0, impression = 0, finish = 0, share = 0, t = 0;
            data.forEach(d => {
                enter += d.enter;
                impression += d.impression;
                finish += d.finish;
                share += d.share;
                t += 1;
            });
            columns.forEach((c, i) => {
                if (c.property === 'enter') {
                    summary[i] = Math.round(enter / t);
                } else if (c.property === 'impression') {
                    summary[i] = Math.round(impression / t);
                } else if (c.property === 'enter_rate') {
                    summary[i] = parseInt(enter / impression * 10000) / 100 + '%';
                } else if (c.property === 'finish_rate') {
                    summary[i] = parseInt(finish / enter * 10000) / 100 + '%';
                } else if (c.property === 'share_rate') {
                    summary[i] = parseInt(share / finish * 10000) / 100 + '%';
                }
            });
            return summary;
        },
    },
}
</script>

<style scoped>

</style>