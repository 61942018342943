<template>
    <div v-loading="loading">
        <div style="display: flex;justify-content: space-between;margin-bottom: 10px;padding: 0 10px">
            <b style="line-height: 28px">{{ title }}
                <el-tooltip content="下载表格" placement="top">
                    <el-icon style="margin-left: 10px;cursor: pointer" @click="downloadTable">
                        <Download/>
                    </el-icon>
                </el-tooltip>
                <el-tooltip content="复制表格到剪贴板" placement="top">
                    <el-icon style="cursor: pointer;margin-left: 10px" @click="copyTable">
                        <DocumentCopy/>
                    </el-icon>
                </el-tooltip>
            </b>
        </div>
        <slot name="header"></slot>
        <el-table :data="data" :id="id" ref="table" :border="border" :style="{width}" @sort-change="sortChange"
                  :height="height" :show-summary="showSummary" :span-method="spanMethod" :cell-style="cellStyle"
                  :summary-method="summaryMethod" :row-class-name="rowClassName" :default-sort="defaultSort">
            <slot></slot>
        </el-table>
        <el-table :data="complete || data" id="complete" ref="complete_table" :border="border" :style="{width}"
                  @sort-change="sortChange" :show-summary="showSummary" :span-method="spanMethod"
                  :cell-style="cellStyle" :summary-method="summaryMethod" :row-class-name="rowClassName"
                  :default-sort="defaultSort" v-show="false">
            <template v-for="node in $slots.default()">
                <component :is="node" v-if="node.props['download-hide']===undefined"></component>
            </template>
        </el-table>
    </div>
</template>

<script>
import {downloadTable, copyTable} from '../libs/utils';

export default {
    name: "DownloadableTable",
    props: {
        id: {
            type: String,
            default: 'table',
        },
        title: String,
        data: Array,
        loading: Boolean,
        tips: String,
        border: Boolean,
        showSummary: Boolean,
        spanMethod: Function,
        cellStyle: Object,
        summaryMethod: Function,
        rowClassName: String,
        defaultSort: Function,
        width: String,
        complete: Array,
        height: [String, Number],
    },
    data() {
        return {
            selected: [], columns: [], downloading: false,
        }
    },
    methods: {
        downloadTable() {
            if (!this.data || this.data.length < 1)
                return
            if (this.complete) {
                downloadTable(this.$refs.complete_table.$refs.bodyWrapper.parentElement, this.title);
            } else {
                downloadTable(this.$refs.table.$refs.bodyWrapper.parentElement, this.title);
            }
        },
        copyTable() {
            if (!this.data || this.data.length < 1)
                return
            if (this.complete) {
                copyTable(this.$refs.complete_table.$refs.bodyWrapper.parentElement);
            } else {
                copyTable(this.$refs.table.$refs.bodyWrapper.parentElement);
            }
        },
        sort(prop, order) {
            this.$refs.table.sort(prop, order)
        },
        sortChange({column, prop, order}) {
            this.$emit('sort-change', {column, prop, order})
        },
        hide(node) {
            console.log(node);
        }
    },
}
</script>

<style scoped>
#table > .download-hide {
    display: none;
}
</style>
