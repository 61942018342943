<template>
    <el-calendar ref="calendar" v-loading="loading">
        <template #date-cell="{data}">
            <div>{{ data.day.split('-').slice(1).join('-') }}</div>
            <div v-if="date_map[data.day]" @click="remove(data.day)">
                <template v-if="date_map[data.day].type === 1">
                    <template v-for="c in collection_list">
                        <el-image style="width: 70px" :src="`https://color-cdn.rabigame.com/${c.cover}`"
                                  lazy v-if="date_map[data.day].data.collection_id === c.id"></el-image>
                    </template>
                </template>
                <template v-else-if="date_map[data.day].type === 2">
                    <template v-for="c in jigsaw_list">
                        <el-image style="width: 70px" :src="`https://color-cdn.rabigame.com/${c.cover}`"
                                  lazy v-if="date_map[data.day].data.jigsaw_id === c.id"></el-image>
                    </template>
                </template>
            </div>
            <div v-else style="height: 70px" @click="open(data.day)"></div>
        </template>
    </el-calendar>
    <el-dialog v-model="dialog_opened">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="类型" prop="type">
                <el-select v-model="form.type" :disabled="loading" @change="changeType">
                    <el-option v-for="(v, k) in promotion_type_enum" :value="parseInt(k)" :label="v"></el-option>
                </el-select>
            </el-form-item>
            <template v-if="form.type === 1">
                <el-form-item label="集合" :rules="[{required: true, message: '请选择集合'}]">
                    <el-select v-model="form.data.collection_id" :disabled="loading" @change="changeCollection"
                               filterable>
                        <el-option v-for="c in collection_list" :value="c.id" :label="c.i18n_name.EN"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="图片" :rules="[{required: true, message: '请选择图片'}]">
                    <el-radio-group v-model="form.data.image_id" :disabled="loading">
                        <el-radio v-for="c in collection_images" :label="c.image_id" style="height: 120px">
                            <template v-for="i in $root.image_list">
                                <el-image style="width: 100px" lazy v-if="i._id === c.image_id"
                                          :src="`https://color-cdn.rabigame.com/${i.thumbnail}`"></el-image>
                            </template>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </template>
            <template v-else-if="form.type === 2">
                <el-form-item label="拼图" :rules="[{required: true, message: '请选择拼图'}]">
                    <el-select v-model="form.data.jigsaw_id" :disabled="loading" filterable>
                        <el-option v-for="c in jigsaw_list" :value="c.id" :label="c.i18n_name.EN"></el-option>
                    </el-select>
                </el-form-item>
            </template>
        </el-form>
        <template #footer>
            <el-button type="primary" text :disabled="loading" @click="reset">取消</el-button>
            <el-button type="primary" :disabled="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {timestampToDate, update} from "../../libs/utils";
import {ElMessageBox} from "element-plus";

export default {
    name: "Promotion",
    data() {
        return {
            loading: false, data: [], promotion_type_enum: {1: '集合', 2: '拼图'}, dialog_opened: false,
            collection_list: [], jigsaw_list: [], image_list: [], collection_images: [], date_map: {},
            form: {
                type: 1,
                data: {collection_id: '', image_id: '',},
                date: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/promotion').then(res => {
                    res.data.data.promotionList.forEach(d => {
                        this.date_map[d.date] = d;
                    });
                }),
                axios.get(`/cms/v1/collection`).then(res => {
                    this.collection_list = res.data.data.collectionList;
                }),
                axios.get(`/cms/v1/jigsaw`).then(res => {
                    this.jigsaw_list = res.data.data.jigsawList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        remove(date) {
            const today = timestampToDate(Date.now());
            // if (date <= today)
            //     return
            ElMessageBox.confirm('确定要删除这一天的推广吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/promotion/${this.date_map[date].id}`).then(res => {
                    this.loading = false;
                    delete this.date_map[date];
                });
            }).catch(_ => {
            })
        },
        changeType() {
            if (this.form.type === 1) {
                this.form.data = {collection_id: '', image_id: ''}
            } else if (this.form.type === 2) {
                this.form.data = {jigsaw_id: ''}
            }
        },
        changeCollection() {
            this.loading = true;
            axios.get(`/cms/v1/collection/${this.form.data.collection_id}/image`).then(res => {
                this.collection_images = res.data.data.imageList;
                this.loading = false;
            })
        },
        open(date) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.form.date = date;
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    axios.post(`/cms/v1/promotion`, this.form).then(res => {
                        this.date_map[res.data.data.date] = res.data.data;
                        this.reset();
                    })
                }
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.el-calendar ::v-deep(.el-calendar-day) {
    height: fit-content;
    width: 160px;
    min-height: var(--el-calendar-cell-width);
}
</style>