<template>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%" v-loading="loading">
        <el-timeline>
            <el-timeline-item v-for="(d, i) in data" :timestamp="`Level${i + 1}`" placement="top">
                <el-card shadow="never">
                    <template #header>
                        <div style="display: flex;justify-content: space-between">
                            <span>{{ d.i18n_name.EN }} ({{ section_style_enum[d.style] }})</span>
                            <div>
                                <el-button type="primary" text :loading="loading" @click="openDialog1(d)">编辑</el-button>
                                <el-button type="success" text :loading="loading" :disabled="i===0" @click="moveUp(i)">
                                    向上
                                </el-button>
                                <el-button type="success" text :loading="loading"
                                           :disabled="i===data.length - 1" @click="moveDown(i)">向下
                                </el-button>
                                <el-button type="danger" :disabled="loading" text @click="removeSection(d)"
                                           size="small">删除
                                </el-button>
                            </div>
                        </div>
                    </template>
                    <vue-draggable-next v-model="d.data" :animation="300" handle=".handle" @change="sortImage(d)">
                        <div class="handle" style="position: relative;display: inline-block;margin: 5px"
                             v-for="(r, index) in d.data">
                            <el-image :src="`https://color-cdn.rabigame.com/${r.thumbnail}`"
                                      style="width: 160px;display: block" lazy></el-image>
                            <el-icon style="position: absolute;top: 5px;right: 5px;cursor: pointer"
                                     @click="removeImage(d, r)">
                                <CircleClose/>
                            </el-icon>
                            <el-date-picker v-model="r.online_date" value-format="YYYY-MM-DD" :disabled="loading"
                                            clearable style="width: 160px" placeholder="上线时间"
                                            @change="updateImage(d, r)"></el-date-picker>
                        </div>
                    </vue-draggable-next>
                    <el-input prefix-icon="Search" style="width: 200px" placeholder="图片id" v-model="d.query"
                              :loading="loading"></el-input>
                    <el-button type="primary" :disabled="loading" @click="addImage(d)">添加</el-button>
                </el-card>
            </el-timeline-item>
        </el-timeline>
        <el-button type="success" size="small" :disabled="loading" @click="openDialog1()">创建关卡</el-button>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset">关闭</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened1" :before-close="reset1">
        <el-form ref="form" :model="form" label-width="120px">
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="显示风格" prop="style" :rules="[{required: true, message: '请选择风格'}]">
                <el-radio-group v-model="form.style" :disabled="loading">
                    <el-radio v-for="(v, k) in section_style_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset1" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "LevelSection",
    components: {VueDraggableNext},
    data() {
        return {
            dialog_opened: false, loading: false, level: null, data: [], dialog_opened1: false, editing: null,
            section_style_enum: {0: '普通风格', 1: '拼图风格'},
            form: {
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                style: 0,
                unfinished: 0,
            },
        }
    },
    methods: {
        init() {
        },
        reset() {
            this.level = null;
            this.dialog_opened = this.loading = false;
        },
        openDialog(level) {
            this.dialog_opened = this.loading = true;
            this.level = level;
            axios.get(`/cms/v1/level/${level.id}/section`).then(res => {
                this.data = res.data.data.sectionList;
                this.data.forEach(d => {
                    this.loadSectionImages(d);
                })
                this.loading = false;
            });
        },
        loadSectionImages(section) {
            axios.get(`/cms/v1/level/${this.level.id}/section/${section.id}/image`).then(res => {
                section.data = [];
                res.data.data.imageList.forEach(r => {
                    this.$root.image_list.forEach(image => {
                        if (r.image_id === image._id) {
                            r.thumbnail = image.thumbnail;
                            section.data.push(r);
                        }
                    })
                })
            });
        },
        openDialog1(item) {
            this.dialog_opened1 = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset1() {
            this.$refs.form.resetFields();
            this.dialog_opened1 = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/level/${this.level.id}/section/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset1();
                        })
                    } else {
                        axios.post(`/cms/v1/level/${this.level.id}/section`, this.form).then(res => {
                            res.data.data.data = [];
                            this.data.push(res.data.data);
                            this.reset1();
                        })
                    }
                }
            })
        },
        addImage(section) {
            this.$root.image_list.forEach(image => {
                if (image.status === 3 && section.query === image.id + '') {
                    this.loading = true;
                    axios.post(`/cms/v1/level/${this.level.id}/section/${section.id}/image`, {image_id: image._id}).then(res => {
                        res.data.data.thumbnail = image.thumbnail;
                        section.data.push(res.data.data);
                        section.query = '';
                        this.loading = false;
                        this.sortImage(section);
                    })
                }
            })
        },
        removeImage(section, r) {
            this.loading = true;
            axios.delete(`/cms/v1/level/${this.level.id}/section/${section.id}/image`, {data: {r_id: r.id}}).then(res => {
                section.data.splice(section.data.indexOf(r), 1);
                this.loading = false;
            })
        },
        updateImage(section, r) {
            this.loading = true;
            axios.put(`/cms/v1/level/${this.level.id}/section/${section.id}/image`, {
                r_id: r.id,
                online_date: r.online_date
            }).then(res => {
                this.loading = false;
            })
        },
        sortImage(section) {
            const sort = [];
            section.data.forEach(r => {
                sort.push(r.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/level/section/image`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        sortSection() {
            const sort = [];
            this.data.forEach(s => {
                sort.push(s.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/level/section`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        removeSection(section) {
            this.loading = true;
            axios.delete(`/cms/v1/level/${this.level.id}/section/${section.id}`).then(res => {
                this.data.splice(this.data.indexOf(section), 1);
                this.loading = false;
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.sortSection();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.sortSection();
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.el-card .el-button + .el-button {
    margin-left: 0;
}
</style>