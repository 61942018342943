<template>
    <div>
        <el-form :model="form" label-width="100px" ref="form" style="display: flex">
            <el-form-item label="模拟日期" prop="date">
                <el-date-picker v-model="form.date" value-format="YYYY-MM-DD" @change="format"></el-date-picker>
            </el-form-item>
            <el-form-item label="安装天数" prop="day">
                <el-input-number v-model="form.day" @change="format"></el-input-number>
            </el-form-item>
        </el-form>
    </div>
    <div style="display: flex">
        <el-card shadow="never" v-for="d in data" style="width: 400px;margin: 0 5px;flex: none">
            <template #header>方案{{ d.label }}</template>
            <el-timeline style="padding-left: 10px">
                <el-timeline-item v-for="line in d.data" :timestamp="line.timestamp" placement="top">
                    <div class="image" v-for="image in line.data" :style="{height: image.square?'240px':'135px'}">
                        <el-image lazy style="width: 135px"
                                  :src="`https://color-cdn.rabigame.com/${image.thumbnail}`"></el-image>
                        <img src="../../static/video.webp" style="position: absolute;height: 20px;top:0;left: 0;"
                             v-if="image.video">
                        <img src="../../static/magic.webp" style="position: absolute; height: 15px;left: 0;bottom: 0"
                             v-if="image.tags.includes(6)">
                        <img src="../../static/wallpaper.webp" v-if="image.tags.includes(2)"
                             style="position: absolute; height: 15px;left: 0;bottom: 0">
                        <img src="../../static/special.webp" style="position: absolute; height: 15px;left: 0;bottom: 0"
                             v-if="image.tags.includes(1)">
                        <img src="../../static/thanksGiving.webp" v-if="image.tags.includes(21)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/halloween.webp" v-else-if="image.tags.includes(22)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/christmas.webp" v-else-if="image.tags.includes(23)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/newYear.webp" v-else-if="image.tags.includes(24)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/valentine.webp" v-else-if="image.tags.includes(25)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/easter.webp" v-else-if="image.tags.includes(26)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/mothersday.webp" v-else-if="image.tags.includes(27)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/independenceDay.webp" v-else-if="image.tags.includes(28)"
                             style="position: absolute;height: 25px;top:0;right: 0;">
                        <img src="../../static/new.webp" style="position: absolute;height: 15px;top: 0;right: 0"
                             v-else-if="image.is_new">
                    </div>
                </el-timeline-item>
            </el-timeline>
        </el-card>
    </div>
</template>

<script>
import axios from "ts-axios-new";
import {timestampToDate} from "../../libs/utils";

export default {
    name: "View",
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], rookie: [], label: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', labels: [],
            form: {
                date: timestampToDate(now), day: 0,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/rookie').then(res => {
                    this.rookie = res.data.data.imageList;
                    this.rookie.forEach(r => {
                        if (!this.labels.includes(r.label)) {
                            this.labels.push(r.label);
                        }
                    })
                }),
            ]).then(_ => {
                this.loading = false;
                this.format();
            })
        },
        format() {
            this.data = [];
            let date = new Date(this.form.date);
            let day = this.form.day;
            for (let i = 0; i < 10; i++) {
                this.labels.forEach(label => {
                    const index = this.label.indexOf(label);
                    if (!this.data[index]) {
                        this.data[index] = {label, data: []}
                    }
                    if (day >= 0) {
                        const timestamp = `第${day}天新手图`;
                        const data = [];
                        this.rookie.filter(r => r.label === label && r.day === day).forEach(r => {
                            this.$root.image_list.forEach(image => {
                                if (image._id === r.image_id) {
                                    image.is_new = this.form.day === day;
                                    data.push(image);
                                }
                            })
                        });
                        if (data.length) {
                            this.data[index].data.push({timestamp, data})
                        }
                    }
                    const timestamp = `${timestampToDate(date)}更新图`
                    const data = this.$root.image_list.filter(image => image.status === 3 && image.online_date === timestampToDate(date));
                    data.sort(function (a, b) {
                        return a.sort < b.sort ? -1 : 1
                    });
                    data.forEach(d => {
                        d.is_new = d.online_date === this.form.date;
                    })
                    if (data.length) {
                        this.data[index].data.push({timestamp, data});
                    }
                });
                day--;
                date -= 24 * 3600 * 1000;
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.image {
    border-radius: 10px;
    padding: 3px;
    width: 135px;
    margin: 5px;
    display: inline-block;
    box-shadow: var(--el-box-shadow-lighter);
    position: relative;
}

.el-image {
    border-radius: 10px;
}
</style>