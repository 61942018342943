<template>
    <div style="display: flex">
        <date-range-picker v-model="form" :disabled="loading" style="width: 200px"></date-range-picker>
        <el-select v-model="form.country" style="width: 200px" @change="init" filterable
                   :disabled="loading" placeholder="全部国家" clearable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.ip_name"
                       :label="c.en_name + ' (' + c.code + ')'">
                {{ c.en_name + ' (' + c.code + ')' }}
            </el-option>
        </el-select>
        <el-select v-model="form.label" clearable placeholder="方案" :disabled="loading">
            <el-option v-for="l in labels" :value="l"></el-option>
        </el-select>
        <el-select v-model="form.days" clearable placeholder="天数" multiple :disabled="loading">
            <el-option v-for="l in days" :value="l"></el-option>
        </el-select>
        <el-button type="primary" :loading="loading" @click="init">查询</el-button>
    </div>
    <downloadable-table :data="dataFilter" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="ID" width="60" prop="image_id"></el-table-column>
        <el-table-column label="预览图" width="120" download-hide>
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`https://color-cdn.rabigame.com/${scope.row.thumbnail}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="完成图" width="120" download-hide>
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`https://color-cdn.rabigame.com/material/${scope.row.key}/finished.webp`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="方案和位置" width="300">
            <template #default="scope">
                <div v-for="r in scope.row.rookie">
                    方案：{{ r.label }} 天数：{{ r.day }} 位置：{{ r.sort }}
                </div>
            </template>
        </el-table-column>
        <el-table-column label="色块数" prop="cells" width="100"></el-table-column>
        <el-table-column label="展示" prop="impression" width="100"></el-table-column>
        <el-table-column label="进入" prop="enter" width="100"></el-table-column>
        <el-table-column label="进入率" prop="enter_rate" width="100">
            <template #default="scope">
                {{ (scope.row.enter / scope.row.impression * 100).toFixed(2) }}%
            </template>
        </el-table-column>
        <el-table-column label="完成" prop="finish" width="100"></el-table-column>
        <el-table-column label="完成率" prop="finish_rate" width="100">
            <template #default="scope">
                {{ (scope.row.finish / scope.row.enter * 100).toFixed(2) }}%
            </template>
        </el-table-column>
    </downloadable-table>
</template>

<script>
import axios from "ts-axios-new";
import {timestampToDate} from "../../libs/utils";
import DateRangePicker from "../../base/DateRangePicker";
import DownloadableTable from "../../base/DownloadableTable";

export default {
    name: "RookieStat",
    components: {DownloadableTable, DateRangePicker},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], rookie: [], labels: [], days: [],
            form: {
                start: timestampToDate(now - 7 * 24 * 3600 * 1000),
                end: timestampToDate(now - 24 * 3600 * 1000),
                country: '',
                label: '',
                days: [],
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/stat/rookie`, {params: this.form}).then(res => {
                this.data = res.data.data.statList;
                this.data.forEach(d => {
                    d.rookie = [];
                    this.rookie.forEach(r => {
                        if (d._id === r.image_id) {
                            d.rookie.push({label: r.label, sort: r.sort, day: r.day});
                        }
                    })
                });
                this.data.sort(function (a, b) {
                    return (a.rookie[0].day - b.rookie[0].day) * 100 + (a.rookie[0].sort - b.rookie[0].sort);
                });
                this.loading = false;
            })
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/rookie`).then(res => {
                this.rookie = res.data.data.imageList;
                this.labels = [];
                this.days = [];
                this.rookie.forEach(r => {
                    if (!this.labels.includes(r.label)) {
                        this.labels.push(r.label);
                    }
                    if (!this.days.includes(r.day)) {
                        this.days.push(r.day);
                    }
                })
            }),
        ]).then(_ => {
            this.init();
        })
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                let valid = !this.form.label;
                if (!valid) {
                    d.rookie.forEach(r => {
                        if (r.label === this.form.label) {
                            valid = true;
                        }
                    })
                }
                valid &= !this.form.days.length;
                if (!valid) {
                    d.rookie.forEach(r => {
                        if (this.form.days.includes(r.day)) {
                            valid = true;
                        }
                    })
                }
                return valid;
            })
        }
    }
}
</script>

<style scoped>

</style>